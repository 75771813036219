import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/thank-you-donate.css"
import { ArrowRightIcon } from "@heroicons/react/solid"

const ThankYouAdopterApplicationPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Adoption Registration Complete" />
    <div className="ty-donate-cont">
      <div className="content">
        <h1>Thanks for submitting your application.</h1>
        <p>A confirmation will be sent to your email.</p>
        <Link to="/" className="btn-primary">Continue <ArrowRightIcon className="w-6 h-6 ml-5" /></Link>
      </div>
    </div>

  </Layout>
}

export default ThankYouAdopterApplicationPage